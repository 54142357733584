<script>
  import { mapActions, mapMutations } from 'vuex';
  import CompleteButton from '@/components/courses/course-details/complete-button/CompleteButton.vue';
  import getThumbnail from '@/utils/getThumbnail.js';

  export default {
    data() {
      return {
        audioInstance: null,
        thumbnailUrl: null,
        currentstate: 'paused',
        isToggleFavoriteLoading: false,
        alreadySavedToHistory: false,
        currentTime: 0,
        audioInterval: null,
        maxReachedDurationIntervalRef: null,
      };
    },
    components: {
      CompleteButton,
    },
    props: {
      course: {
        type: Object,
        required: true,
      },
    },
    computed: {
      heartIconDefault() {
        return require('@/assets/course/heart-icon-default.svg');
      },
      currentTimeFormatted: {
        get: function () {
          if (this.audioInstance && this.audioInstance.currentTime) {
            return this.audioInstance.currentTime.toFixed(0);
          } else {
            return 0;
          }
        },
        set: function (newVal) {
          if (newVal) this.audioInstance.currentTime = newVal;
        },
      },
    },
    async created() {
      this.setUpAudio();
      await this.setThumbnailUrl();
    },
    beforeDestroy() {
      this.setCourseCurrentlyPlayed(null);
      this.stopTrackingMaxReachedDuration();
      this.restartAudio();
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage']),
      ...mapMutations('CoursesModule', ['setCourseCurrentlyPlayed']),
      ...mapActions('CoursesModule', ['switchFavoriteState', 'addCourseToUserHistory']),
      ...mapActions('StatsModule', ['saveMaxReachedDuration']),
      async setThumbnailUrl() {
        try {
          this.thumbnailUrl = await getThumbnail({
            url: this.course.coverUrl,
            folder: 'CoursesCovers',
          });
        } catch {
          this.thumbnailUrl = this.course.coverUrl;
        }
      },
      secondsToMinutes(seconds) {
        if (!seconds) return '00:00';
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      },
      setUpAudio() {
        // init audio player instance
        this.audioInstance = new Audio(this.course.audioUrl);

        // add event listeners
        this.audioInstance.onplay = () => {
          //? onplay will fire the first time the users play the audio
          this.currentstate = 'playing';
          this.setCourseCurrentlyPlayed(this.course.id);
          this.startTrackingMaxReachedDuration();
        };
        this.audioInstance.onpause = () => {
          this.currentstate = 'paused';
          this.setCourseCurrentlyPlayed(null);
          this.stopTrackingMaxReachedDuration();
        };
        this.audioInstance.onplaying = () => {
          //? onplaying will fire every time the user pause and resume the audio
          //? avoid creating any setInterval functions here, as it will create so many
          this.currentstate = 'playing';
        };
      },
      playAudio() {
        this.audioInstance.play();
        this.saveToHistory();
        this.setSlidertrackValue();
      },
      // ontimeupdate event is the cause of the problem, after I tried everything,
      // I tried to default back to the last method (intervals), and not only is the
      // bug fixed, but the audio is running smoother as well!
      setSlidertrackValue() {
        this.audioInterval = setInterval(() => {
          this.currentTime = this.audioInstance.currentTime;
        }, 1500);
      },
      pauseAudio() {
        this.audioInstance.pause();
        clearInterval(this.audioInterval);
        this.audioInterval = null;
      },
      restartAudio() {
        this.audioInstance.pause();
        this.audioInstance.currentTime = 0;
        this.currentTime = this.audioInstance.currentTime;
      },
      async toggleFavouriteStatus() {
        this.isToggleFavoriteLoading = true;
        try {
          await this.switchFavoriteState(this.course.id);
          this.$emit('toggleFavouriteStatus');
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isToggleFavoriteLoading = false;
        }
      },
      startTrackingMaxReachedDuration() {
        // Start a timer to track the duration of the video that is being played
        const MAX_REACHED_DURATION_INTERVAL_DURATION = 60000;
        this.maxReachedDurationIntervalRef = setInterval(() => {
          if (this.audioInstance && this.audioInstance.currentTime) {
            // save the max reached duration of the course
            this.saveMaxReachedDuration(this.audioInstance.currentTime);
          }
        }, MAX_REACHED_DURATION_INTERVAL_DURATION);
      },
      stopTrackingMaxReachedDuration() {
        // Stop the timer
        clearInterval(this.maxReachedDurationIntervalRef);
      },
      async saveToHistory() {
        // exit if this is already done
        if (this.alreadySavedToHistory) {
          console.log('Already saved To History!');
          return;
        }
        try {
          await this.addCourseToUserHistory(this.course.id);
          this.alreadySavedToHistory = true;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        }
      },
      changeAudioPosition(newVal) {
        if (newVal) {
          this.audioInstance.currentTime = newVal;
          this.$nextTick(() => (this.currentTime = newVal));
        }
      },
    },
  };
</script>
